// import ShortLogo from "../../assets/cyphr-logo-short.png";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Youtube } from "react-bootstrap-icons";
import { Container, Row, Col } from "react-bootstrap";

import CyphrLogo from "../../assets/cyphr-logo-revised.png";

import classes from "./SiteFooter.module.css";

const SiteFooter = (props) => {
  return (
    <div className={classes.footer}>
      <Container>
        <Row className="">
          <Col md={4}>
            <Row>
              <Col>
                <img src={CyphrLogo} height="90px" />
                <p>
                  At <b>Cyphr Solutions</b>, we bridge the gap between business
                  and technology. Our expert consultants deliver innovative
                  solutions tailored to your unique needs, ensuring seamless
                  integration and enduring success. Connect with us to transform
                  your IT challenges into opportunities.
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <div className={classes.social}>
                <Link>
                  <Linkedin />
                </Link>
                <Link>
                  <Facebook />
                </Link>
                <Link>
                  <Instagram />
                </Link>
                <Link>
                  <Youtube />
                </Link>
              </div>
              <div className={classes.copyright}>
                <p>Copyright &copy; 2024 Cyphr Solutions Inc.</p>
              </div>
            </Row>
          </Col>
          <Col>
            <Row className={classes.links}>
              <Col></Col>
              <Col>
                <h4>Explore</h4>
                <ul>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/industries">Industries</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/about/testimonials">Testimonials</Link>
                  </li>
                </ul>
              </Col>
              <Col>
                <h4>About Us</h4>
                <ul>
                  <li>
                    <Link to="/about/leadership">Leadership</Link>
                  </li>
                  <li>
                    <Link to="/industries">Industries</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </Col>
              <Col>
                <h4>Legal</h4>
                <ul>
                  <li>
                    <Link to="/legal/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/legal/terms">Terms of Use</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SiteFooter;
