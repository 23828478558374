import { Container, Col, Row } from "react-bootstrap";

const Home = (props) => {
  return (
    <Container fluid>
      <Container>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Home;
