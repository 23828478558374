import { Fragment, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import CSSTransition from "react-transition-group/CSSTransition";

import classes from "./Navmenu.module.css";

import { NavbarData } from "../../store/PageData";

const Navmenu = (props) => {
  const [menuContent, setMenuContent] = useState();
  const { closeBackdropHandler } = props;

  const { activeNavbarTab } = props;
  const { height } = props;
  const { show: isShowing } = props;
  const nodeRef = useRef(null);

  const title = menuContent?.displayName;
  const description = menuContent?.description;
  const path = menuContent?.id;

  useEffect(() => {
    if (activeNavbarTab) {
      setMenuContent(
        NavbarData.find((item) => {
          if (item.id == activeNavbarTab) {
            return item;
          }
        })
      );
    }
  }, [activeNavbarTab]);

  console.log(menuContent);

  const menu = (
    <div>
      <Row>
        <Col md={5} className={classes.splash}>
          <h1>{title}</h1>
          <img src={menuContent?.image} height="250px" />
          <p>{description}</p>
          <Link to={path} onClick={closeBackdropHandler}>
            Read More <ArrowRight className={classes.icon} />
          </Link>
        </Col>
        <Col>
          <Row className={classes.links}>
            <Col>
              <ul>
                {menuContent?.links &&
                  menuContent?.links.map((link) => {
                    return (
                      <li key={link.name}>
                        <Link to={link.url} onClick={closeBackdropHandler}>
                          {link.name}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  return (
    <Container>
      <CSSTransition
        in={isShowing}
        timeout={700}
        nodeRef={nodeRef}
        classNames={{
          enter: classes.fadeEnter,
          enterActive: classes.fadeEnterActive,
          enterDone: classes.fadeEnterDone,
          exit: classes.fadeExit,
          exitActive: classes.fadeExitActive,
        }}
      >
        <div
          ref={nodeRef}
          className={classes.navmenu}
          style={{ height: height }}
        >
          <Container>{menu}</Container>
        </div>
      </CSSTransition>
    </Container>
  );
};

export default Navmenu;
