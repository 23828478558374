import { Container, Col, Row } from "react-bootstrap";

import classes from "./PageHeader.module.css";

const PageHeader = (props) => {
  const { title, description } = props;

  return (
    <div className={classes.header}>
      <Container>
        <Row className={`${classes.headerRow}`}>
          <Col>
            <h1>{title}</h1>
            <p>{description}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageHeader;
