import { useState, useEffect } from "react";

import { Button } from "react-bootstrap";

import Input from "./Input";
import { EMAIL_VALIDATION_REGEX } from "../../utils/constants";

import classes from "./ContactForm.module.css";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.match(EMAIL_VALIDATION_REGEX);

const ContactForm = (props) => {
  const [formIsValid, setFormIsValid] = useState(false);

  const [enteredName, setEnteredName] = useState("");
  const [enteredNameIsValid, setEnteredNameIsValid] = useState(false);
  const [enteredNameTouched, setEnteredNameTouched] = useState(false);

  const [enteredPhone, setEnteredPhone] = useState("");
  const [enteredPhoneIsValid, setEnteredPhoneIsValid] = useState(false);
  const [enteredPhoneTouched, setEnteredPhoneTouched] = useState(false);

  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredEmailIsValid, setEnteredEmailIsValid] = useState(false);
  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);

  const [enteredMessage, setEnteredMessage] = useState("");
  const [enteredMessageIsValid, setEnteredMessageIsValid] = useState(false);
  const [enteredMessageTouched, setEnteredMessageTouched] = useState(false);

  const nameBlurHandler = () => setEnteredNameTouched(true);
  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);

    isNotEmpty(event.target.value)
      ? setEnteredNameIsValid(true)
      : setEnteredNameIsValid(false);
  };

  const phoneBlurHandler = () => setEnteredPhoneTouched(true);
  const phoneChangeHandler = (event) => {
    setEnteredPhone(event.target.value);

    isNotEmpty(event.target.value)
      ? setEnteredPhoneIsValid(true)
      : setEnteredPhoneIsValid(false);
  };

  const emailBlurHandler = () => setEnteredEmailTouched(true);
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);

    isNotEmpty(event.target.value)
      ? setEnteredEmailIsValid(true)
      : setEnteredEmailIsValid(false);
  };

  const messageBlurHandler = () => setEnteredMessageTouched(true);
  const messageChangeHandler = (event) => {
    setEnteredMessage(event.target.value);

    isNotEmpty(event.target.value)
      ? setEnteredMessageIsValid(true)
      : setEnteredMessageIsValid(false);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) return;
  };

  const resetFormFields = () => {
    // Reset form fields
    setEnteredName("");
    setEnteredNameTouched(false);

    setEnteredEmail("");
    setEnteredEmailTouched(false);

    setEnteredPhone("");
    setEnteredPhoneTouched(false);

    setEnteredMessage("");
    setEnteredMessageTouched(false);
  };

  useEffect(() => {
    if (enteredNameIsValid && enteredPhoneIsValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    console.log(enteredNameIsValid);
  }, [enteredNameIsValid]);

  return (
    <form className={classes.form} onSubmit={formSubmitHandler}>
      <Input
        name="name"
        value={enteredName}
        label="Your Name:"
        type="text"
        placeholder="Enter your full name"
        onChange={nameChangeHandler}
        onBlur={nameBlurHandler}
        hasError={!enteredNameIsValid && enteredNameTouched}
        errorText="Enter a valid first name"
        autoFocus
      />
      <Input
        name="phone"
        value={enteredPhone}
        label="Phone Number:"
        type="text"
        placeholder="Enter your phone number"
        onChange={phoneChangeHandler}
        onBlur={phoneBlurHandler}
        hasError={!enteredPhoneIsValid && enteredPhoneTouched}
        errorText="Enter a valid phone number"
      />
      <Input
        name="email"
        value={enteredEmail}
        label="Business Email:"
        type="text"
        placeholder="Enter your business email address"
        onChange={emailChangeHandler}
        onBlur={emailBlurHandler}
        hasError={!enteredEmailIsValid && enteredEmailTouched}
        errorText="Enter a valid email address"
      />
      <Input
        name="email"
        value={enteredMessage}
        label="Message:"
        type="textarea"
        placeholder="How can we help you with your IT and Security needs?"
        onChange={messageChangeHandler}
        onBlur={messageBlurHandler}
        hasError={!enteredMessageIsValid && enteredMessageTouched}
        errorText="Enter a valid message"
      />
      <Button type="submit">Submit</Button>
    </form>
  );
};

export default ContactForm;
