import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container, Col, Row } from "react-bootstrap";

import classes from "./Navbar.module.css";

const Navbar = (props) => {
  const [activeTab, setActiveTab] = useState("services");
  const { closeBackdropHandler } = props;
  const { onMouseOver: mouseHoverHandler } = props;

  useEffect(() => {
    props.setActiveNavbarTab(activeTab);
  }, [activeTab]);

  const navbarActiveTabHandler = (event) => {
    setActiveTab(event.target.id.split("navbar-")[1]);
  };

  return (
    <div>
      <Col className="">
        <nav className={classes.navbar} onMouseOver={mouseHoverHandler}>
          <ul>
            <li>
              <Link
                to="/about"
                id="navbar-about"
                onMouseOver={navbarActiveTabHandler}
                onClick={closeBackdropHandler}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarActiveTabHandler}
                id="navbar-careers"
                to="/careers"
                onClick={closeBackdropHandler}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarActiveTabHandler}
                id="navbar-partnerships"
                to="/partnerships"
                onClick={closeBackdropHandler}
              >
                Partnerships
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarActiveTabHandler}
                id="navbar-industries"
                to="/industries"
                onClick={closeBackdropHandler}
              >
                Industries
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarActiveTabHandler}
                id="navbar-services"
                to="/services"
                onClick={closeBackdropHandler}
              >
                Services
              </Link>
            </li>
          </ul>
        </nav>
      </Col>
    </div>
  );
};

export default Navbar;
