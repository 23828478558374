import { useEffect, useState, Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";

import AbstractHeader from "../assets/header-abstract.png";
import PageHeader from "../components/PageHeader/PageHeader";

import classes from "./Common.module.css";

import { PageData } from "../store/PageData";

const PageDetail = (props) => {
  const [pageContent, setPageContent] = useState(null);
  const { pageId } = props;

  const title = pageContent?.displayName;
  const description = pageContent?.description;

  useEffect(() => {
    setPageContent(
      PageData.find((item) => {
        if (item.id == pageId) {
          return item;
        }
      })
    );
  }, [pageContent, pageId]);

  return (
    <Fragment>
      <PageHeader title={title} description={description} />
    </Fragment>
  );
};

export default PageDetail;
