import { Fragment, useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { LifePreserver } from "react-bootstrap-icons";
import { EnvelopeFill } from "react-bootstrap-icons";

import classes from "./SiteHeader.module.css";
import Backdrop from "../UI/Backdrop";
import Navmenu from "../Nav/Navmenu";
import Navbar from "../Nav/Navbar";

import CyphrLogo from "../../assets/cyphr-logo-black-revised.png";

const SiteHeader = (props) => {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [navbarIsShowing, setNavbarIsShowing] = useState(false);
  const [activeNavbarTab, setActiveNavbarTab] = useState("services");

  const navbarRef = useRef(null);
  const topbarRef = useRef(null);

  useEffect(() => {
    setNavbarHeight(
      navbarRef.current.offsetHeight + topbarRef.current.offsetHeight + 20
    );
  }, []);

  const closeBackdropHandler = () => {
    const timeout = setTimeout(() => {
      setNavbarIsShowing(false);

      return () => {
        clearTimeout(timeout);
      };
    }, 150);
  };

  const menuItemHoverHandler = (event) => {
    setNavbarIsShowing(true);
  };

  return (
    <div className={classes.header}>
      <Backdrop
        navbarHeight={navbarHeight}
        show={navbarIsShowing}
        onClose={closeBackdropHandler}
      />
      <Container
        fluid
        className={classes.topbar}
        onMouseOver={closeBackdropHandler}
      >
        <Container>
          <Row ref={topbarRef}>
            <Col>
              <ul>
                <li>
                  <a
                    href="https://quickassist.screenconnect.com"
                    target="_blank"
                  >
                    <LifePreserver className={classes.icon} /> Quick Support
                  </a>
                </li>
                <li>
                  <Link to="/contact">
                    <EnvelopeFill className={classes.icon} />
                    Contact
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className={classes.bottombar} fluid>
        <Container>
          <Row ref={navbarRef} className="align-items-center">
            <Col>
              <Link to="/">
                <img height="90" src={CyphrLogo} />
              </Link>
            </Col>
            <Col>
              <Navbar
                onMouseOver={menuItemHoverHandler}
                setActiveNavbarTab={setActiveNavbarTab}
                closeBackdropHandler={closeBackdropHandler}
              />
            </Col>
          </Row>
        </Container>
        <Navmenu
          show={navbarIsShowing}
          closeBackdropHandler={closeBackdropHandler}
          activeNavbarTab={activeNavbarTab}
          height="500px"
        />
      </Container>
    </div>
  );
};

export default SiteHeader;
