import { useEffect, useState, useRef } from "react";

import { Container, Row, Col } from "react-bootstrap";
import CSSTransition from "react-transition-group/CSSTransition";

import classes from "./Contact.module.css";
import ContactForm from "../components/Form/ContactForm";
import PageHeader from "../components/PageHeader/PageHeader";

const Contact = (props) => {
  const [isShowing, setIsShowing] = useState(false);
  const leftColumnNodeRef = useRef(null);
  const rightColumnNodeRef = useRef(null);

  useEffect(() => {
    if (isShowing == true) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsShowing(true);

      return () => {
        clearTimeout(timeout);
      };
    }, 500);

    console.log(isShowing);
  }, [isShowing]);

  const leftColumnClasses = [classes.column, classes.leftColumn];
  const rightColumnClasses = [classes.column, classes.rightColumn];

  return (
    <div className={classes.contact}>
      <PageHeader title="Contact Us" description="Some text" />
      <Container>
        <Row className={classes.row}>
          <CSSTransition
            in={isShowing}
            classNames={{
              enter: classes.enterLeft,
              enterActive: classes.enterLeftActive,
              enterDone: classes.enterLeftDone,
            }}
            nodeRef={leftColumnNodeRef}
            timeout={700}
          >
            <Col
              className={leftColumnClasses.join(" ")}
              ref={leftColumnNodeRef}
            >
              <div>
                <p>
                  Cyphr Solutions Inc. <br />
                  1274 Clays Trail <br />
                  Oldsmar, FL 34677
                </p>
              </div>
            </Col>
          </CSSTransition>

          <CSSTransition
            in={isShowing}
            classNames={{
              enter: classes.enterRight,
              enterActive: classes.enterRightActive,
              enterDone: classes.enterRightDone,
            }}
            nodeRef={rightColumnNodeRef}
            timeout={700}
          >
            <Col
              className={rightColumnClasses.join(" ")}
              ref={rightColumnNodeRef}
            >
              <ContactForm />
            </Col>
          </CSSTransition>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
